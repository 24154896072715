<template>
  <div
    class="forbidden full-width row wrap justify-center items-center content-center"
    style="min-height: calc(100vh - 50px)"
  >
    <q-card class="row col-xs-8 col-sm-5 col-md-3">
      <q-card-section
        class="full-width row wrap justify-center items-center content-center"
      >
        <q-avatar rounded size="150px" class="">
          <img src="img/EscudoUNAMwhite.svg" />
        </q-avatar>
      </q-card-section>
      <q-card-section
        class="full-width row wrap justify-center items-center content-center"
      >
        <div class="">
          <q-avatar>
            <img :src="basicProfile['imageUrl']" />
          </q-avatar>
        </div>

        <div class="text-h6 q-ml-sm">
          {{ basicProfile["name"] }}
        </div>
      </q-card-section>
      <q-card-section
        class="full-width row wrap justify-center items-center content-center"
      >
        <span class="text-center text-h5">No tienes los permisos</span>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-actions
        class="full-width row wrap justify-center items-center content-center"
      >
        <q-btn
          class="full-width text-capitalize"
          color="primary"
          @click="requestAccess"
        >
          Solicita Acceso
        </q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
// import { watch } from "vue";
import { router } from "@/router";
import { basicProfile } from "../api/gapi/gapi";

export default {
  name: "Forbidden",
  components: {
    //  VinculacionExterna: defineAsyncComponent(() =>
    //    import("@/components/VinculacionExterna.vue")
    //  ),
    // VinculacionExterna,
  },
  setup() {
    console.log("Forbidden ready");
    // handleClientLoad();
    // onMounted(() => {});
    function requestAccess() {
      console.log("requestAcces");
      // window.open("/login", "_self");
      router.push("/login");
    }
    return {
      basicProfile,
      requestAccess,
    };
  },
};
</script>
<style lang="css" scoped></style>
