<template>
  <div
    class="pagenotfound full-width row wrap justify-center items-center content-center"
    style="min-height: calc(100vh - 50px)"
  >
    <q-card class="row col-xs-8 col-sm-5 col-md-3">
      <q-card-section
        class="full-width row wrap justify-center items-center content-center"
      >
        <q-avatar rounded size="150px" class="">
          <img src="img/EscudoUNAMwhite.svg" />
        </q-avatar>
      </q-card-section>
      <q-card-section
        class="full-width row wrap justify-center items-center content-center"
      >
        <span class="text-center text-h5">Pagina no encontrada</span>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-actions
        class="full-width row wrap justify-center items-center content-center"
      >
        <q-btn
          class="full-width text-capitalize"
          color="primary"
          @click="accept"
        >
          Aceptar
        </q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import { router } from "@/router";
export default {
  name: "PageNotFound",
  components: {
    //  VinculacionExterna: defineAsyncComponent(() =>
    //    import("@/components/VinculacionExterna.vue")
    //  ),
    // VinculacionExterna,
  },
  setup() {
    console.log("PageNotFound ready");
    // handleClientLoad();
    // onMounted(() => {});
    function accept() {
      console.log("Aceptar");
      // window.open("/", "_self");
      router.push("/login");
    }
    return {
      accept,
    };
  },
};
</script>
<style lang="css" scoped></style>
